import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

import { IBreadcrumb } from '@ea/models';
import { BehaviorSubject } from 'rxjs';
import { AuthFacade } from '@ea/auth';
import { MatIconModule } from '@angular/material/icon';
import { ProductLikeService } from 'src/app/services/shared/ProductLiks/productLikes.service';

@Component({
  selector: 'ea-account-page',
  standalone: true,
  imports: [CommonModule, RouterLink, NgFor, NgIf,MatIconModule],
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss'],
})
export class AccountPageComponent implements OnInit {
  breadcrumbs$: BehaviorSubject<IBreadcrumb[] | any>;
  countProducts : number = 0; 
  constructor(private auth: AuthFacade,private productLiksService : ProductLikeService) {
    this.breadcrumbs$ = new BehaviorSubject([]);
  }

  ngOnInit() {
    this.productLiksService.getProductLiks().subscribe((x: any)=> {
      if(x){
        this.countProducts = x.length;
      }
    });
    const breadcrumbs = [
      {
        label: 'My Account',
        url: '/',
      },
      {
        label: 'My Details',
        url: '/account-details',
      },
      {
        label: 'My Addresses',
        url: '/addresses',
      },
    ];
    this.breadcrumbs$.next(breadcrumbs);
  }

  logout() {
    this.auth.logout('');
  }
}
