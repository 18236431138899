<section class="pb-5">
  <div class="account container">
    <div class="header pt-5 pb-5">
      <h1 class="font-bold">My Account</h1>
    </div>
    <div class="row ps-3 pe-3">
      <div class="col-xs-12 col-sm-6 col-lg-4">
        <a
          [routerLink]="'account-details'"
          class="card-border focus-outline"
        >
          <div class="card bg-theme-surface text-theme-on-surface">
            <h2 class="font-bold">My Details</h2>
            <p class="text-theme-on-surface">
              Edit your personal and login details.
            </p>
          </div>
        </a>
      </div>
      <div class="col-xs-12 col-sm-6 col-lg-4">
        <a
          [routerLink]="'addresses'"
          class="card-border focus-outline"
        >
          <div class="card bg-theme-surface text-theme-on-surface">
            <h2 class="font-bold">My Addresses</h2>
            <p class="text-theme-on-surface">View and manage your addresses.</p>
          </div>
        </a>
      </div>
      <div class="col-xs-12 col-sm-6 col-lg-4">
        <a
          [routerLink]="'my-cards'"
          class="card-border focus-outline"
        >
          <div class="card bg-theme-surface text-theme-on-surface">
            <h2 class="font-bold">My Cards</h2>
            <p class="text-theme-on-surface">View and manage your cards.</p>
          </div>
        </a>
      </div>
      <div class="col-xs-12 col-sm-6 col-lg-4">
        <a
          [routerLink]="'orders'"
          class="card-border focus-outline"
        >
          <div class="card bg-theme-surface text-theme-on-surface">
            <h2 class="font-bold">My Orders</h2>
            <p class="text-theme-on-surface">
              View, manage or return your orders.
            </p>
          </div>
        </a>
      </div>
      <div class="col-xs-12 col-sm-6 col-lg-4">
        <a
          [routerLink]="'my-favorite-products'"
          class="card-border focus-outline"
        >
          <div class="card bg-theme-surface text-theme-on-surface">
            <h2 class="font-bold">My Favourite Products         <span class="like"> <mat-icon>favorite</mat-icon>  {{this.countProducts}} </span>      </h2>
            <p class="text-theme-on-surface">View Favourite Products.</p>
          </div>
        </a>
      </div>

      <div class="col-xs-12 col-sm-6 col-lg-4">
        <a
          [routerLink]="'charities'"
          class="card-border focus-outline"
        >
          <div class="card bg-theme-surface text-theme-on-surface">
            <h2 class="font-bold">Charities</h2>
            <p class="text-theme-on-surface">View Charities.</p>
          </div>
        </a>
      </div>
      <div class="col-xs-12 col-sm-6 col-lg-4">
        <div
          class="p-0 card-border focus-outline logout-button"
          (click)="logout()"
        >
          <div class="card bg-theme-surface text-theme-on-surface">
            <h2 class="font-bold text-left">Logout</h2>
            <p class="text-left text-theme-on-surface">
              Log out of your account.
            </p>
          </div>
        </div>
      </div>





    </div>
  </div>
</section>
